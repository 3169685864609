import _ from 'lodash';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { IoIosShareAlt, IoIosUndo } from 'react-icons/io';
import { FaPlay } from 'react-icons/fa';

import { timestamp, useDateUpdate } from 'src/utils/helper';
import { useCountviews } from 'src/utils/countview';
// import { Viewer } from '.';
import { sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import dynamic from 'next/dynamic';

const WidgetSocialShare = dynamic(import('../widgets/widget-social-share'));
const Image = dynamic(import('src/components/image'));

const CardV = ({
  data,
  pinTag = true,
  showImage = true,
  showBlurb = false,
  showBadge = false,
  showByline = true,
  heading = '',
  showTitle = true,
  showTime = true,
  showVideo = true,
  share = true,
  showCountview = true,
  external = false,
  excerptBlurb = 2,
  eventDataLayer
}) => {
  if (_.isEmpty(data)) return null;
  const badge = data?.subCategory?.th ? data?.subCategory?.th : data?.section?.th;
  const [countView, setCountView] = useState(data?.pageviews || 0);
  useEffect(
    () =>
      (async () => {
        try {
          const _pageviews = await useCountviews(data);
          setCountView(_pageviews);
        } catch (err) {
          console.error(`${timestamp()} ==========> CARD_V ERROR :`, err.message);
        }
      })(),
    []
  );

  return (
    <CardWrapper className='card-v border-item'>
      {/* ---------------------------------- IMAGE --------------------------------- */}
      {showImage && (
        <div className='card-thumb'>
          <div className='img-scale-animate position-relative'>
            <a
              href={data?.link || '#'}
              aria-hidden='true'
              tabIndex='-1'
              aria-label={data?.title}
              title={data?.title}
              target={external ? '_blank' : '_self'}
              rel={external ? 'noopener noreferrer' : ''}
              //! DATALAYER
              onClick={() => sendDataLayerGlobal({ ...eventDataLayer })}
            >
              <Image image={data?.image} title={data?.title} external={external} type='CARD_MEDIUM' />
              {pinTag && (
                <div className={'position-absolute tag-pin'}>
                  <p>{heading || badge}</p>
                </div>
              )}
            </a>
            {data?.is_video && showVideo ? (
              <div className='position-absolute video-card-share'>
                <FaPlay />
              </div>
            ) : (
              <>
                {share && (
                  <>
                    <div className='position-absolute share-card --desktop'>
                      <IoIosShareAlt />
                      <WidgetSocialShare articleLink={data?.link} />
                    </div>
                    <div className='share-card --mobile'>
                      <IoIosShareAlt />
                      {/* <IoIosUndo /> */}
                      <WidgetSocialShare articleLink={data?.link} />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}
      {/* --------------------------------- CONTENT -------------------------------- */}
      <a
        href={data?.link || '#'}
        aria-hidden='true'
        tabIndex='-1'
        aria-label={data?.title}
        title={data?.title}
        target={external ? '_blank' : '_self'}
        rel={external ? 'noopener noreferrer' : ''}
        //! DATALAYER
        onClick={() => sendDataLayerGlobal({ ...eventDataLayer })}
      >
        <div className='card-content'>
          {showTitle && <h3 className='text-excerpt-3'>{data?.title}</h3>}
          {showBlurb && <p className={`blurb text-excerpt-${excerptBlurb}`}>{data?.blurb}</p>}
          <div className='meta-contents'>
            {showByline && !_.isEmpty(data.byline) && (
              <div className='byline'>
                <span>by</span> <span>{data.byline}</span>
              </div>
            )}
            <div className='meta'>
              {showTime && (
                <p className='date-time'>
                  <span>{useDateUpdate(data?.published_at) || data?.datePeriod}</span>
                </p>
              )}
              {/* {showCountview && <Viewer data={countView} />} */}
            </div>
          </div>
        </div>
      </a>
    </CardWrapper>
  );
};

const CardWrapper = styled.div`
  cursor: pointer;
  position: relative;

  .card-content {
    h3 {
      &:hover {
        text-decoration: underline;
      }
      transition: 0.5s ease-out;
      font-weight: 500;
      font-size: 18px;
      line-height: 1.45;
      margin: 14px 0px 14px 0;
      min-height: 78px;
      @media (max-width: 768px) {
        font-size: 16px;
        min-height: 68px;
      }
    }
  }
  .blurb {
    margin-bottom: 10px;
  }
  .meta-contents {
    display: flex;
    width: 100%;
    .byline {
      color: #000000;
      span {
        &:nth-child(1) {
          text-transform: lowercase;
        }
      }
      font-size: 12px !important;
      &:after {
        content: '/';
        color: #626262;
        padding: 0 5px;
      }
    }
    p,
    span {
      font-size: 12px;
      @media (max-width: 767px) {
        font-size: 10px;
      }
    }
    .read-more {
      display: block;
    }
  }
  &:hover {
    .img-scale-animate {
      .tag-pin {
        color: rgba(255, 230, 0, 1);
        box-shadow: inset 230px 0 0 0 #000;
      }
    }
  }
  .card-thumb {
    .img-scale-animate {
      position: relative;
      padding-right: 0;
      aspect-ratio: 16/9;
      .share-card {
        @media (max-width: 690px) {
          z-index: 1;
          background-color: #ffe600;
          border-radius: 100%;
          padding: 3px;
          margin-top: 15px;
          width: fit-content;
          margin-bottom: 2px;
        }
        &.--desktop {
          @media (max-width: 690px) {
            display: none;
          }
        }
        &.--mobile {
          display: none;
          @media (min-width: 690px) {
            display: flex;
          }
        }
        &:hover {
          .article-share {
            left: 38px;
            ul {
              li {
                opacity: 1;
              }
            }
          }
        }
        .article-share {
          transition: all 0.6s ease-in-out;
          position: absolute;
          bottom: -6px;

          left: 38px;
          @media (max-width: 690px) {
            /* bottom: -8px; */
            left: 25px !important;
          }
          ul {
            list-style-type: none;
            display: flex;
            justify-content: center;
            align-items: center;
            li {
              z-index: 0;
              opacity: 0;
              @media (max-width: 690px) {
                opacity: 1;
                padding: 0 2px;
                svg {
                  border-radius: 100%;
                  width: 22px;
                  height: 22px;
                }
              }
            }
            li:nth-child(1) {
              transition: opacity 0.3s cubic-bezier(0.35, 0.7, 0.32, 0.9) 50ms, background-color 0.3s cubic-bezier(0.35, 0.7, 0.32, 0.9) 0s;
            }
            li:nth-child(2) {
              transition: opacity 0.3s cubic-bezier(0.35, 0.7, 0.32, 0.9) 0.1s, background-color 0.3s cubic-bezier(0.35, 0.7, 0.32, 0.9) 0s;
            }
            li:nth-child(3) {
              transition: opacity 0.3s cubic-bezier(0.35, 0.7, 0.32, 0.9) 0.15s, background-color 0.3s cubic-bezier(0.35, 0.7, 0.32, 0.9) 0s;
            }
            li:nth-child(4) {
              transition: opacity 0.3s cubic-bezier(0.35, 0.7, 0.32, 0.9) 0.2s, background-color 0.3s cubic-bezier(0.35, 0.7, 0.32, 0.9) 0s;
            }
          }
        }
        /* @media (max-width: 768px) {
          display: none;
        } */
      }
    }
  }
`;

export default CardV;
